import React from "react";
import { useSelector } from "react-redux";
import { LayoutSimpleEventsRecap } from "./components/recapPage/LayoutSimpleEventsRecap";
import { selectHeroImg } from "../../../pages/SimpleEventsSubscribeForm/selectors";
import Header from "./components/Hero/Hero";
import LayoutSubscriptionForm from "./components/subscriptionForm/LayoutSubscriptionForm";

function LayoutSimpleEventsSubscribeForm({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const heroData = useSelector(selectHeroImg());
  const showRecap = useSelector(
    (state) => state.SimpleEventsSubscribeForm.showRecap
  );

  return (
    <>
      {heroData?.fileReference && <Header data={heroData} />}
      {!showRecap ? (
        <LayoutSubscriptionForm data={data} />
      ) : (
        <LayoutSimpleEventsRecap events={data?.eventItems?.items} />
      )}
    </>
  );
}

export default LayoutSimpleEventsSubscribeForm;
