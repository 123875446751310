import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CameraIcon from "../../assets/camera";
import Compressor from "compressorjs";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMessage } from "../../../utils/notifications/TriggerNotification";
import { setOverallFileSize } from "../../../pages/FormAnnuncio/FormAnnuncioSlice";

const baseStyle = {
  border: "dashed #8F8F8F",
  display: "flex",
  color: "#8F8F8F",
  flexDirection: "column",
  gap: "15px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "11px",
  lineHeight: "20px",
  letterSpacing: "1px",
  outline: "none",
  cursor: "pointer",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  border: "dashed #2196f3",
};

const acceptStyle = {
  border: "dashed #00e676",
};

const rejectStyle = {
  border: "dashed #ff1744",
};

const TOTAL_MAX__FILE_SIZE = 100000; //10MB

export default function MyDropzone({
  onChange,
  opacity = 1,
  className = "",
  inputName = "",
  uploadText = false,
  multiple = true,
}) {
  const overallFileSize = useSelector(
    (state) => state.FormAnnuncio.overallFileSize
  );
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        new Compressor(file, {
          quality: 0.6,
          width: 512,
          success(result) {
            //check superamento 10MB
            if (overallFileSize + result.size < TOTAL_MAX__FILE_SIZE) {
              dispatch(setOverallFileSize(result.size));
              onChange(result);
            } else {
              showErrorMessage(
                `Cannot upload file "${result.name}", overall files size limit reached.`
              );
            }
          },
          error(err) {
            console.error(err.message);
          },
        });
      });
    },
    [overallFileSize]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple: multiple,
      accept: { "image/*": [] },
      onDrop,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div {...getRootProps({ style })} className={className}>
      <input {...getInputProps()} />
      <CameraIcon></CameraIcon>
      {uploadText && (
        <div style={{ maxWidth: "200px", textAlign: "center" }}>
          Trascina qui le immagini o clicca per selezionarle
        </div>
      )}
    </div>
  );
}
