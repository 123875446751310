import { createSlice } from "@reduxjs/toolkit";

export const SIMPLE_EVENTS_FORM_ISCRIZIONE_SCOPE = "SimpleEventsSubscribeForm";

export const initialState = {
  components: {},
  checkedConsents: [],
  status: null,
  errorMessage: "",
  showRecap: false,
  resultPost: [],
  defaultBodyPayload: {},
  savedBodyPayload: {},
  thereIsSubscription: false,
};

const SimpleEventsSubscribeForm = createSlice({
  name: SIMPLE_EVENTS_FORM_ISCRIZIONE_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setBodyPayload(state, action) {
      state.defaultBodyPayload = action.payload;
    },
    setShowRecap(state, action) {
      state.showRecap = action.payload;
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    initBodyPayload(state, action) {
      let subscriptions = [];

      action.payload.map((eventItem) => {
        if (eventItem?.userSubscriptions?.length) {
          state.thereIsSubscription = true;
          const { appointmentId, eventCode, consents } =
            eventItem?.userSubscriptions?.[0];
          let currentSubscription = {
            appointmentId,
            NumGuests: 0,
            eventCode,
            consents,
          };
          subscriptions.push(currentSubscription);
        }
      });
      const checkList = action.payload[0].appointments[0].checkList;

      state.defaultBodyPayload = {
        checklist: checkList,
        type: "eventi-semplici",
        events: subscriptions,
      };
      state.savedBodyPayload = state.defaultBodyPayload;
    },
    setResultPost(state, action) {
      state.resultPost = action.payload;
      state.showRecap = true;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataSimpleEvents: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  setBodyPayload,
  setCheckedConsents,
  initBodyPayload,
  setShowRecap,
  setResultPost,
  resetFormDataSimpleEvents,
} = SimpleEventsSubscribeForm.actions;
export default SimpleEventsSubscribeForm;
