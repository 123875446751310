import { takeLatest, put, select } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS, FETCH_YEAR_DOC } from "./constants";
import { companyComActions } from "./companyComSlice";

function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);

    yield put(companyComActions.setComponents(result.data.data));

    const tabsObject = yield select(
      (state) =>
        state.companyCom.components.components.find(
          (el) => el.resourceType === "fi-corporate-comunications-catalogue"
        ).data
    );
    const tabsMap = [
      {
        category: "internal-comunications",
        title: tabsObject.internalComunicationsTitle,
      },
      {
        category: "organizational-arrangements",
        title: tabsObject.organizationalArrangementsTitle,
      },
    ];
    yield put(companyComActions.setTabsMap(tabsMap));
    yield put(companyComActions.setDocuments(tabsObject.items));
    yield put(companyComActions.setDocumentsThisYear(tabsObject.items));
  } catch (e) {
    yield put(companyComActions.setError(e));
  }
}
function* fetchYearDocuments({ payload }) {
  const url = `${payload.url}/list?startDate=${payload.value}-01-01&endDate=${payload.value}-12-31`;
  try {
    const result = yield axiosCallGet(url, payload.token);
    yield put(companyComActions.setDocuments(result.data.data));
    yield put(companyComActions.setDocumentsLastYear(result.data.data));
    yield put(companyComActions.setIsDocLoaded(true));
  } catch (e) {
    yield put(companyComActions.setError(e));
  }
}

export default function* corporateComSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
  yield takeLatest(FETCH_YEAR_DOC, fetchYearDocuments);
}
