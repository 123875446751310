import styled from "styled-components";

export const WrapperCardEvent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 30px;
`;

export const CardOverlay = styled.div`
  background: #181818;
  opacity: 0.5;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const ContainerCardEvents = styled.div`
  background-color: var(--alternative-bg-color);
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.light
      ? "box-shadow:0px 13px 15px 3px rgba(255,255,255, 0.06)"
      : "box-shadow:  0px 13px 15px 3px rgba(0, 0, 0, 0.06)"};
  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;
export const ContainerCardImg = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 185px;
  width: 177px;
  min-width: 177px;
  border-radius: 4px;
  overflow: hidden;
  img {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    height: 185px;
    width: 100%;
    min-width: 177px;
    top: 0;
  }
  @media (max-width: 1279px) {
    width: 100%;
    img {
      width: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }
`;
export const CardDateAndImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  & p {
    z-index: 2;
    font-size: 46px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 1px;
    margin: 0;
  }
  & p:nth-child(2) {
    z-index: 2;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;
export const ContainerCardDetails = styled.div`
  width: 100%;
  ${(props) => (props.height === "true" ? "height: 135px" : "height: 180px")};
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.height === "true"
      ? "justify-content: space-between"
      : "justify-content: center"};
  padding: 3px 25px;
  a {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--cta-color);
    cursor: pointer;
    width: 220px;
  }
  @media (max-width: 1279px) {
    width: 90%;
    justify-content: space-between;
    align-items: start;
    padding: 25px 0;
  }
`;
export const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  button {
    position: relative;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 35px;
    padding: unset;
  }
  button:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
export const InternalConteiner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 1279px) {
    justify-content: flex-start;
    width: 100%;
  }
`;
export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1279px) {
    justify-content: flex-start;
    width: 100%;
  }
`;
export const ContainerCardBodyText = styled.div`
  display: flex;
  & label {
    font-size: 9px;
    line-height: 10.8px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    color: rgb(112, 112, 112);
    font-weight: 400;
  }
  & p {
    width: 100%;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    ${(props) =>
      props.height === "true" ? "height: 42px" : "height: initial"};
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin: 8px 0;
    // &:hover {
    //   position: absolute;
    //   z-index: 2;
    //   background: white;
    //   padding: 5px;
    //   border: 1px solid;
    //   border-radius: 5px;
    //   width: 210px;
    //   -webkit-line-clamp: 3;
    //   margin: 12px -10px;
    // }
  }
`;
export const ContainerCardBodyTextGh = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  & p {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 42px;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin: 8px 0;
  }
`;
export const ContainerCardBodyTextData = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  & label {
    font-size: 9px;
    line-height: 10.8px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    color: rgb(112, 112, 112);
    font-weight: 400;
  }
  & p {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin: 8px 0;
  }
`;

export const ButtonDelete = styled.svg.attrs({
  width: "40",
  height: "45",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
`;
export const ButtonDeleteCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M20 42.5634C31.0457 42.5634 40 33.1852 40 21.6167C40 10.0481 31.0457 0.669922 20 0.669922C8.9543 0.669922 0 10.0481 0 21.6167C0 33.1852 8.9543 42.5634 20 42.5634ZM20 40.4687C29.9411 40.4687 38 32.0284 38 21.6167C38 11.205 29.9411 2.7646 20 2.7646C10.0589 2.7646 2 11.205 2 21.6167C2 32.0284 10.0589 40.4687 20 40.4687Z",
})`
  fill: #d4d2d2;
  pointer-events: none;
`;
export const ButtonDeleteImg = styled.path.attrs({
  d: "M22.745 15.1578C22.6584 13.6489 21.4625 12.4531 20 12.4531C18.4812 12.4531 17.25 13.7426 17.25 15.3333V15.5951H14.321C13.4534 15.5951 12.75 16.2985 12.75 17.1661H27.25C27.25 16.2985 26.5466 15.5951 25.679 15.5951H22.75V15.3333L22.745 15.1578ZM21.25 15.5951V15.3333C21.25 14.6103 20.6904 14.0241 20 14.0241C19.3528 14.0241 18.8205 14.5393 18.7565 15.1994L18.75 15.3333V15.5951H21.25ZM15.25 18.999V28.1869C15.25 28.463 15.4739 28.6869 15.75 28.6869H24.25C24.5261 28.6869 24.75 28.463 24.75 28.1869V18.999H26.25V28.2579C26.25 29.3624 25.3546 30.2579 24.25 30.2579H15.75C14.6454 30.2579 13.75 29.3624 13.75 28.2579V18.999H15.25ZM19 18.999V26.854C18.1716 26.854 17.5 26.1824 17.5 25.354V18.999H19ZM22.5 26.854V18.999H21V25.354C21 26.1824 21.6716 26.854 22.5 26.854Z",
})`
  fill: var(--main-color);
  pointer-events: none;
`;
