import { Container, SendButton, GenericTitle } from "./style";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setBodyPayload,
  setCheckedConsents,
} from "../../../pages/FormIscrizioneGiftHouse2024/FormIscrizioneGiftHouse2024Slice";
import { SetUserRegistration } from "../../../pages/FormIscrizioneGiftHouse2024/action";

//components
import Header from "./components/Header/Header";
import SedeDiLavoro from "./components/ComponentGiftHouse/SedeDiLavoro";
import RitiroGift from "./components/ComponentGiftHouse/RitiroGift";
import ComponentPrivacy from "./components/ComponentPrivacy/ComponentPrivacy";
import CollectGiftMessages from "./components/ComponentGiftHouse/infoGreyMexContainer/CollectGiftMessages";
import WorkLocationMessage from "./components/ComponentGiftHouse/infoGreyMexContainer/WorkLocationMessage";
import { isEqual } from "lodash";

function LayoutFormIscrizioneGiftHouse2024({ data, heroData, dataModal }) {
  const [allowed, setAllowed] = useState(null);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.search.replace(/\?path=/, "");

  const pathCms = url
    .split("?")[0]
    .replace(/subscribe-form-gh/, "subscribe-recap-gh");
  const updatePathCms = pathCms.split("-form")[0];

  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);

  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneGiftHouse2024.bodyPayload
  );
  const savedBodyPayload = useSelector(
    (state) => state.FormIscrizioneGiftHouse2024.savedBodyPayload
  );

  const checkedConsents = useSelector(
    (state) => state.FormIscrizioneGiftHouse2024.checkedConsents
  );

  const childsInAgeSubscribed =
    bodyPayload?.childrenInAgeSubscribed?.length > 0;
  const haveChildsInAge = bodyPayload?.childsInAge?.length > 0;
  const childsRegisteredByConsort = bodyPayload?.childrenInAgeSubscribed?.find(
    (e) => e?.subscriptionInfo?.subscribedBy !== bodyPayload?.userFiscalCode
  )
    ? true
    : false;

  const isSubmitActive =
    !isEqual(bodyPayload, savedBodyPayload) &&
    bodyPayload?.appointmentId &&
    bodyPayload?.SedeLavoro &&
    checkedConsents?.length === data?.policiesRules?.items?.length &&
    !checkedConsents?.find((el) => el.checked === false);

  function generateHtml(argument) {
    return { __html: argument };
  }
  const calculateAppointmentId = (value) => {
    const shipmentPlace =
      value === "Milano" ? "shipmentMilano" : "shipmentOther";

    let appointmentId;

    data?.event?.appointments?.forEach((appointment) => {
      appointment?.slots?.forEach((slot) => {
        if (
          slot?.configurations?.find(
            (conf) =>
              conf.groupName === "deliveryMode" &&
              conf.fieldValue === shipmentPlace
          )
        ) {
          appointmentId = slot.id;
        }
      });
    });

    return appointmentId;
  };

  const handleSedeLavoro = (e) => {
    const { value } = e.target;
    const appointmentValue =
      value === "Milano" || value === "Other"
        ? calculateAppointmentId(value)
        : null;
    dispatch(setCheckedConsents([]));
    dispatch(
      setBodyPayload({
        ...bodyPayload,
        SedeLavoro: value,
        appointmentId: appointmentValue,
      })
    );
    setAllowed(null);
  };

  const path = `subscription-recap-gh?path=${updatePathCms}-recap?eventCode=${bodyPayload?.eventCodeGiftHouse}`;
  //post con payload dinamico
  const onSubmitHandler = (e) => {
    e.preventDefault();
    //controllo policy prima di fare post
    if (isSubmitActive) {
      const responseBody = bodyConstructor();
      setAllowed(true);
      dispatch(
        SetUserRegistration({
          responseBody,
          token,
          lang,
          path,
        })
      );
    } else {
      setAllowed(false);
    }
  };

  //popolamento familyArray
  const parseFamilyArray = () => {
    const guestAndFamilyNotEnabled =
      !data?.event?.familyEnabled && !data?.event?.guestEnabled;

    let familyArray;
    if (guestAndFamilyNotEnabled) {
      familyArray = [];
    } else if (
      (haveChildsInAge && !childsInAgeSubscribed) ||
      (childsInAgeSubscribed && !childsRegisteredByConsort)
    ) {
      familyArray = bodyPayload?.familyComponents;
    } else if (
      childsRegisteredByConsort ||
      bodyPayload?.childsInAge?.length < 1
    ) {
      familyArray = [];
    }

    return familyArray;
  };

  const bodyConstructor = () => {
    const familyArray = parseFamilyArray();
    const guestAndFamilyEnabled =
      !data?.event?.familyEnabled && data?.event?.guestEnabled;

    const responseBody = {
      eventCode: data?.event?.code,
      NumGuests: guestAndFamilyEnabled ? familyArray?.length : 0,
      optionsValue: [],
      guid: data?.userSubscriptions?.[0]?.guid,
      id: data?.userSubscriptions?.[0]?.id,
      isNew: data?.userSubscriptions?.[0]?.id ? false : true,
      appointmentId: bodyPayload?.appointmentId,
      familyMembers: familyArray,
      consents: bodyPayload?.consentsGiftHouse,
    };

    return responseBody;
  };

  return (
    <>
      {heroData?.fileReference && <Header data={heroData} />}
      <Container>
        <GenericTitle>
          <h2>{data?.giftHouse?.title}</h2>
          {data?.giftHouse?.description && (
            <p
              dangerouslySetInnerHTML={generateHtml(
                `${data?.giftHouse?.description}`
              )}
            />
          )}
        </GenericTitle>
        <form
          onSubmit={(e) => {
            onSubmitHandler(e);
          }}
          ref={formRef}
        >
          <CollectGiftMessages data={data} />
          <SedeDiLavoro
            check={bodyPayload?.SedeLavoro}
            data={data?.giftHouse}
            onChange={handleSedeLavoro}
            allowed={allowed}
          />
          <WorkLocationMessage data={data} />
          {bodyPayload?.SedeLavoro !== null && (
            <RitiroGift
              data={data}
              checkAppointmentID={bodyPayload?.appointmentId}
              allowed={allowed}
              bodyPayload={bodyPayload}
            />
          )}
          {bodyPayload?.SedeLavoro && bodyPayload?.appointmentId && (
            <ComponentPrivacy data={data} allowed={allowed} />
          )}
          <SendButton
            className={
              isSubmitActive ? "submitButton activeSubmit" : "submitButton"
            }
          >
            {!data?.userSubscriptions?.[0]?.appointmentId
              ? data?.button?.subscribeLbl
              : data?.button?.modifyLbl}
          </SendButton>
        </form>
      </Container>
    </>
  );
}

export default LayoutFormIscrizioneGiftHouse2024;
