import React from "react";
import { DetailContainerData, DetailDataLabel } from "./style";
import { useSelector } from "react-redux";

export function DetailInfo({ data, eventCode }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const subscription = data?.subscription?.primaryAppointment;

  const eventSlotInfo = data?.eventCodeTitleList?.find(
    (el) => el.eventCode === eventCode
  );

  const getSubscriptionDay = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <>
      <DetailContainerData>
        <DetailDataLabel>{data?.eventLabel}</DetailDataLabel>
        <p>{eventSlotInfo?.title}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>
          {eventSlotInfo?.pickSlotTitle
            ? data?.slotTitleLabel
            : data?.slotLabel}
        </DetailDataLabel>
        <p>
          {eventSlotInfo?.pickSlotTitle
            ? subscription?.titleTxt
            : getSubscriptionDay(
                subscription?.appointmentStartDate,
                subscription?.appointmentEndDate,
                lang
              )}
        </p>
      </DetailContainerData>
      {subscription?.locationTxt && (
        <DetailContainerData>
          <DetailDataLabel>{data?.locationLabel}</DetailDataLabel>
          <p>{subscription?.locationTxt}</p>
        </DetailContainerData>
      )}
    </>
  );
}

export default React.memo(DetailInfo);
