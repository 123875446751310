import { useEffect, useState } from "react";
import CustomSelect from "../../../customSelect/CustomSelect";
import { useSelector } from "react-redux";

export default function SelectInput({
  data,
  handleEventInfo,
  showTextOnInput,
}) {
  const bodyPayload = useSelector(
    (state) => state.SimpleEventsSubscribeForm.defaultBodyPayload
  );

  const [placeholderString, setPlaceholderString] = useState(data?.slotLabel);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const existSlots = data?.appointments?.length > 0;

  const getAppointmentDate = (startDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
    });
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  useEffect(() => {
    if (
      existSlots &&
      bodyPayload?.events?.find((sub) => sub?.eventCode === data?.eventCode)
    ) {
      let currentGroupSelected = data?.appointments?.find(
        (appointment) =>
          +appointment?.id ===
          bodyPayload?.events?.find((sub) => sub?.eventCode === data?.eventCode)
            ?.appointmentId
      );
      setPlaceholderString(
        getAppointmentDate(currentGroupSelected?.appointmentStartDate)
      );
    }
  }, [existSlots, bodyPayload?.events, selectedAppointment]);

  return (
    <CustomSelect
      name={data?.title}
      placeholderString={placeholderString}
      defaultValue={
        bodyPayload?.events?.find((sub) => sub?.eventCode === data?.eventCode)
          ?.appointmentId
      }
      onChange={(e) => {
        handleEventInfo(e, data?.eventCode, data?.consents);
        setSelectedAppointment(e?.currentTarget?.value);
      }}
    >
      {data?.appointments?.map((appointment, ind) => {
        if (
          appointment?.availablePlacesFrontEnd - appointment?.takenSeats > 0 ||
          data?.savedAppointmentId === appointment?.id
        ) {
          return (
            <optgroup
              key={"appointment" + ind}
              label={getAppointmentDate(appointment?.appointmentStartDate)}
            >
              <option
                className="data-evento"
                value={appointment?.id}
                id={appointment?.id}
                key={appointment?.id}
              >
                {showTextOnInput(data, appointment)}
              </option>
            </optgroup>
          );
        } else {
          return "";
        }
      })}
    </CustomSelect>
  );
}
