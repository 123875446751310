import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  initBodyPayload,
  setComponents,
  setError,
  setResultPost,
} from "./SimpleEventsSubscribeFormSlice";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";
import { FETCH_COMPONENTS, SET_USER_REGISTRATION } from "./constants";
import { fetchMyPageComponents } from "../MyPage/actions";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-simple-events-subscribe-form.json")
    // );
    const eventInfoData = result?.data?.data?.components?.find(
      (el) => el.resourceType === "fi-events/fi-internal-events-subscribe-form"
    )?.data?.eventItems?.items;
    const eventInfoError = result?.data?.data?.components?.find(
      (el) => el.resourceType === "fi-events/fi-internal-events-subscribe-form"
    )?.data?.error403;

    yield put(setComponents(result.data.data));
    yield put(initBodyPayload(eventInfoData));
    if (eventInfoError) {
      yield put(setError({ status: 403, message: "Unauthorized" }));
      return;
    }
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export function* SetUserRegistration({ payload }) {
  try {
    const token = payload.token;
    const myPagePath = payload.myPagePath;
    const messageSuccess =
      payload.lang === "it"
        ? "Richiesta inviata con successo"
        : "Request sent successfully";
    document.body.classList.add("loading");

    let eventsData = [...payload.responseBody.events];
    eventsData[0] = { ...eventsData[0], primaryEvent: true };
    payload.responseBody = { ...payload.responseBody, events: eventsData };

    const result = yield axiosCallPost(
      `/${payload.lang}/news-and-events/events/subscribe`,
      payload.responseBody,
      token
    );
    yield put(setResultPost(result.data.output));
    yield put(fetchMyPageComponents({ path: myPagePath, token }));
  } catch (e) {
    const messageError =
      payload.lang === "it"
        ? "Errore nel salvataggio dati"
        : "Error while saving data";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(SET_USER_REGISTRATION, SetUserRegistration);
}
