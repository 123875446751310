import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  documents: [],
  documentsThisYear: [],
  documentsLastYear: [],
  tabsMap: [],
  isDocLoaded: false,
  tabCategory: "internal-comunications",
  filterYear: new Date().getFullYear(),
  status: null,
  errorMessage: "",
};

const companyCom = createSlice({
  name: "companyCom",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.isDocLoaded = true;
      state.components = action.payload;
    },
    setDocuments(state, action) {
      state.documents = action.payload;
    },
    setDocumentsThisYear(state, action) {
      state.documentsThisYear = action.payload;
    },
    setDocumentsLastYear(state, action) {
      state.documentsLastYear = action.payload;
    },
    setTabsMap(state, action) {
      state.tabsMap = action.payload;
    },
    setTabCategory(state, action) {
      state.tabCategory = action.payload;
    },
    setFilterYear(state, action) {
      state.filterYear = action.payload;
    },
    setIsDocLoaded(state, action) {
      state.isDocLoaded = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    resetCompanyCom: () => initialState,
  },
});

export const companyComActions = companyCom.actions;
export default {
  name: companyCom.name,
  reducer: companyCom.reducer,
};
